/* Search bar styles */
.input-group {
  display: flex;
  align-items: center;
}

.input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-group input {
  flex-grow: 1;
}

input {
  border: 1px solid #ddd;
  padding: 0.5rem;
  width: 100%;
  outline: none; /* Removes the outline on focus */
}

input:focus {
  border-color: #fcfcfd;
  box-shadow: none;
}

/* Card styles */
.file-card,
.transcription-box {
  display: flex;
  flex-direction: column;
  height: 691px !important;
  max-height: 720px !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0px !important;
}
/* Card rounded edges */
.card {
  border-radius: 8px; /* Smooth but sharp edges */
  overflow: hidden; /* Ensures the rounded edges apply to child elements */
}
/* 2-Line description with ellipsis */
.description-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold !important;
  margin-bottom: -15px !important;
}

.card-text {
  color: #6c757d;
}

button {
  margin-top: auto;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.transcription-box {
  padding: 20px;
  font-size: 1rem;
  color: #333;
  overflow-y: scroll;
}

.transcription-box h5 {
  font-size: 1.25rem;
  font-weight: bold;
}
.transcription-box p {
  font-size: 15px;
  margin-bottom: 5px;
  line-height: 1.5;
  margin-top: 5px;
}

.transcription-box::-webkit-scrollbar {
  width: 6px;
}

.transcription-box::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 2px;
}

.transcription-box::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.dropdown-list::-webkit-scrollbar {
  width: 6px;
}

.dropdown-list::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 2px;
}

.dropdown-list::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.description-container::-webkit-scrollbar {
  width: 6px;
}

.description-container::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 2px;
}

.description-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

/* Responsive layout adjustments */
@media (max-width: 768px) {
  .file-card,
  .transcription-box {
    margin-top: 20px;
    height: auto;
  }
}

.description-container {
  max-height: 120px;
  overflow-y: auto;
  margin-top: -10px !important;
}

.last-updated-box {
  height: 115px !important;
}

ul li {
  list-style-type: none;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.card-body {
  text-align: center;
}

/* Ensure dropdown menu doesn't overflow */
.dropdown-menu {
  max-width: 300px; /* Adjust this as per your text length */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for long text */
}

.dropdown-menu.show {
  left: 0; /* Aligns dropdown properly to the left edge */
  right: auto;
}
/* Ensure card uniformity */
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-title {
  font-size: 0.95rem;
}

.card-text {
  font-size: 0.85rem;
}

/* Fix alignment for dropdowns */
.row .col {
  padding-right: 8px;
  padding-left: 8px;
}

.dropdown-toggle {
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.duration-text {
  font-size: 14px;
  color: #6c757d;
  margin-top: 8px;
  margin-left: 3px;
}

@media (max-width: 767px) {
  .d-flex {
    flex-direction: column;
    align-items: flex-start;
  }
}
/* Search bar adjustments */
input[type="text"] {
  width: 100%;
  padding: px;
  font-size: 14px;
  border-radius: 4px;
}

/* Spacing between filter dropdowns */
.dropdown-toggle,
.dropdown-menu {
  padding: 5px 10px;
}
.description-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.views-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.views-likes-container {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: white;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.views-likes-container div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.views-likes-container div:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: transform 0.2s ease;
}
/* Custom Styling */
.btn-custom {
  background-color: #007bff;
  color: white;
}

.btn-custom:hover {
  background-color: #0056b3;
}

.hover-effect:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.dropdown-toggle {
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 0.875rem;
}

.dropdown-menu {
  border-radius: 5px;
}
