/* KnowledgeBaseTable.css */

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #5a4c4c;
}

th {
  background-color: #f4f4f4;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #dcdcdc;
}

h1, h2, h3 {
  font-size: 24px;
  margin-top: 20px;
}

iframe {
  margin-top: 20px;
}
